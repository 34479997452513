// Angular
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
// Model
import { AuthData } from '@core/models/auth-data';
// RxJS
import { tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { RequestReceipt } from '@core/models/request-receipt.model';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import svgIconList from 'assets/icons.json';
import { environment } from '@env/environment';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  memberBalance$ = this.dashboardHttpService.memberBalance$;
  memberUsername$ = this.dashboardHttpService.memberUsername$;

  memberCurrency = '';

  user_data = JSON.parse(localStorage.getItem('user_data'));
  verificationRejected = localStorage.getItem('rejected_verification');
  loggedUser$: Observable<AuthData>;

  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;
  sitePrefix: string;
  hideReferralSite = ['12HUAT', 'BX99', 'YE55', 'U388', 'WYN8', 'MBS66', 'WILD33']; // QPRO2, QPRO3, QPRO4, QPRO5, QPRO6, QPRO7, QPRO8

  private subscriptions: Subscription[] = [];

  constructor(
    private dashboardHttpService: DashboardHttpService,
    private walletHttpService: WalletHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private translateService: TranslateService,
    private router: Router,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private eventEmitterService: EventEmitterService,
    ) { }

  ngOnInit() {
    this.sitePrefix = environment.sitePrefix.toUpperCase();

    this.eventEmitterService.updateVerificationStorageEmitter.subscribe(() => {
        this.verificationRejected = localStorage.getItem('rejected_verification');
    });
    if (this.router.url !== '/member/transfer') {
      this.setWalletDetails();
    } else {
      this.memberCurrency = this.user_data.currency.code;
    }
    this.loggedUser$ = this.store.pipe(
      select(loggedUser),
      tap((user) => {
        this.dashboardHttpService.refreshMemberName(user?.username);
      })
    );
    this.translateService.use((localStorage.getItem('language_code').toLowerCase()).toLocaleLowerCase());
    this.depositData = RequestReceiptHttpService.requestReceipt;
    this.newRequestIncoming.subscribe(() => {
      this.depositData = RequestReceiptHttpService.requestReceipt;
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onRefreshBalance() {
    this.loadingBar.start();
    this.setWalletDetails();
  }

  private setWalletDetails() {
    let interval = setInterval(() => {
      if (this.walletHttpService.memberBalance !== undefined) {
        this.subscriptions.push(
          this.walletHttpService.memberBalance.subscribe((res) => {
            this.dashboardHttpService.refreshMemberBalance(res.balance);
            this.memberCurrency = res.currency_code ? res.currency_code : '$';
            this.loadingBar.complete();
          })
        );
        clearInterval(interval);
      }
    }, 500)
  }

}
