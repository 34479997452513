<ng-container *ngIf="(isLoggedIn$ | async) && !onMaintenancePage">
  <div id="mySidebar" class="sidebar" (mouseenter)="toggleSidebar()" (mouseleave)="toggleSidebar()">

    <!-- hamburger menu icon-->
    <div class="menu-icon-container">
      <svg class="menu-hamburger menu-hamburgerRotate180 menu-hamburger" viewBox="0 0 100 100" width="70">
        <path class="line top" d="m 30,33 h 40 c 0,0 8.5,-0.68551 8.5,10.375 0,8.292653 -6.122707,9.002293 -8.5,6.625 l -11.071429,-11.071429" />
        <path class="line middle" d="m 70,50 h -40" />
        <path class="line bottom" d="m 30,67 h 40 c 0,0 8.5,0.68551 8.5,-10.375 0,-8.292653 -6.122707,-9.002293 -8.5,-6.625 l -11.071429,11.071429" />
      </svg>
    </div>

    <!-- <ng-container *ngIf="(userProfile | async) as user">
      <div class="welcome-box">
        <img [src]="user.member_group?.image" [alt]="user.member_group?.name + ' Badge Icon'" *ngIf="user.member_group.image">
        <div *ngIf="collapse == false" class="m-b-35">
          <p>{{ 'Welcome' | translate }}</p>
          <a routerLink="/member/account">
            <h3>{{ user.username ? user.username : userData.username }}</h3>
          </a>
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngIf="userProfileDetail">
      <div class="welcome-box">
        <img [src]="userProfileDetail.member_group?.image" [alt]="userProfileDetail.member_group?.name + ' Badge Icon'" *ngIf="userProfileDetail.member_group.image">
        <div *ngIf="collapse == false" class="m-b-35">
          <p>{{ 'Welcome' | translate }}</p>
          <a routerLink="/member/account">
            <h3>{{ userProfileDetail.username ? userProfileDetail.username : userData.username }}</h3>
          </a>
        </div>
      </div>
    </ng-container>

    <ul class="sidepanel-container">
      <li class="p-0">
        <div class="balance" *ngIf="walletBalance && memberBalanceIsLoading == 0" [ngClass]="'levelColor' + memberGroupColor">
          <div class="wallet-currency">{{ walletBalance.currency_code }}</div>
          <h2 [class]="collapse ? 'collapse-amount' : ''"> {{ collapse && walletBalance.balance >= 1000 ? (walletBalance.balance | shortNumber:2) : (walletBalance.balance | number : '1.2-2') }} </h2>
        </div>
        <div *ngIf="memberBalanceIsLoading > 0" class="d-flex align-items-center justify-content-center balance">
          <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
        </div>
      </li>
      <li class="p-t-45">
        <a (click)="onRestore()">
          <div [innerHTML]="svg.restoreIcon | safeHtml" [ngClass]="!collapse ? '' : 'collapse-img' "></div>
          <span>{{ 'Restore' | translate }}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/member/wallet', {tab: 'deposit'}]">
          <div [innerHTML]="svg.depositIcon | safeHtml" [ngClass]="!collapse ? '' : 'collapse-img' "></div>
          <span>{{ 'Deposit' | translate }}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/member/wallet', {tab: 'transfer'}]">
          <div [innerHTML]="svg.transferIcon | safeHtml" [ngClass]="!collapse ? '' : 'collapse-img' "></div>
          <span>{{ 'Transfer' | translate }}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/member/wallet', {tab: 'withdraw'}]">
          <div [innerHTML]="svg.withdrawIcon | safeHtml" [ngClass]="!collapse ? '' : 'collapse-img' "></div>
          <span>{{ 'Withdraw' | translate }}</span>
        </a>
      </li>
    </ul>

    <div class="active-rewards" *ngIf="collapse == false">
      <ng-container *ngIf="activeRewards.length >= 1; else noActiveRewards">
        {{ 'Active Rewards' | translate }}
        <a routerLink="/member/reward">
          <ng-container *ngFor="let item of activeRewards">
            <p> {{ item.name | titlecase }} </p>
          </ng-container>
        </a>
      </ng-container>
      <ng-template #noActiveRewards>
        {{ 'No Active Reward' | translate }}
      </ng-template>
    </div>
  </div>
  <img *ngIf="(mode != 'Members Only' || isUserLoggedIn()) && luckySpinDomain !== ''" src="../../../../assets/images/lucky-spin-wheel.gif" class="lucky-spin-img mb-2" id="lucky-spin" (click)="onLuckySpin()">
</ng-container>

<div *ngIf="(mode != 'Members Only' || isUserLoggedIn())" class="contact-side-panel" [ngClass]="{ 'contact-zh' : currentLanguageCode === 'ZH' }">
  <p>{{ 'Contact Us' | translate }}</p>
  <ng-container *ngIf="contactList">
    <ng-container *ngFor="let item of contactList">
      <div *ngIf="item.contact_type === 'Support'" class="contact-content" (click)="onContact(item.locales[0].link)">
        <a>
          <img [src]="'/assets/images/social/' + item.image_icon" [alt]="item.name + ' Icon'">
        </a>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="countryCode === 'TH' && isLine">
  <div class="line-floating">
    <a>
      <img src="/assets/images/social/line-float.png" (click)="onOpenLine()">
    </a>
  </div>
</ng-container>



<!-- Contact Panel
  <div class="contact-panel">
    <div class="row">
      <div class="col-3 p-r-0 text-right">
        <img src="assets/images/sidebar/contact-us-panel.png">
      </div>
      <div class="col-9 p-l-0 contact-panel-box">
        <img src="assets/images/sidebar/24.7-member-service.png" class="p-t-10">
        <a (click)="onOpenChatBox()"><img src="assets/images/sidebar/live-chat.png" class="m-r-10">Chat now</a>
        <a routerLink="/content/contact-us"><img src="assets/images/sidebar/contact-us.png" class="m-r-10">test@gmail.com</a>
      </div>
    </div>
  </div>
End Contact Panel -->
<!-- <livechat-widget #liveChatWidget *ngIf="!resetLiveChat" licenseId="10515072" [group]="language" [visitor]="visitor"></livechat-widget> -->
<!-- Download Panel
  <div class="download-panel">
    <div class="row">
      <div class="col-9 p-r-0 download-panel-box">
        <a href="#"><img src="assets/images/sidebar/android.png" class="m-r-10">Android</a>
        <a href="#"><img src="assets/images/sidebar/ios.png" class="m-r-10">IOS</a>
      </div>
      <div class="col-3 p-l-0 text-left">
        <img src="assets/images/sidebar/download-panel.png" style="min-width: 55px">
      </div>
    </div>
  </div>
  End Download Panel -->


<app-swal-alert [success]="true" [message]="messages$ | async" [flashStyle]="true"></app-swal-alert>