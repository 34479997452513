// Component
import { MemberStatusModalComponent } from '../../dialog/member-status-modal/member-status-modal.component';
import { VerificationModalComponent } from '../../dialog/verification-modal/verification-modal.component';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { ContactListHttpService } from '@core/services/contact-list-http.service';
// Angular
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
// RxJS
import { debounceTime, distinctUntilChanged, catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
// Third Party
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { environment } from '@env/environment';
import svgIconList from 'assets/icons.json';

declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  private validateSubject = new BehaviorSubject<any>(null);

  svg: any = svgIconList;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;

  form: FormGroup;
  max = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
  messages$ = this.portalMemberProfileHttpService.messages$;
  isSuccess = this.portalMemberProfileHttpService.isSuccess;
  userName: string;
  checkValidity = false;
  userData = null;
  countryCode = localStorage.getItem('country_code');
  redirectLink: string;
  sitePrefix = environment.sitePrefix;
  validatingEmail = false;
  buttonLoading = false;

  private datePickerSubscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    public dialog: MatDialog,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    private datePipe: DatePipe,
    public loadingBar: LoadingBarService,
    private dashboardHttpService: DashboardHttpService,
    private router: Router,
    private translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
    private contactListHttpService: ContactListHttpService,
  ) { }

  ngOnInit() {
    this.profileFormInit();
    this.fetchData();
    this.verificationMessage();
    this.eventEmitterService.profile = this.eventEmitterService.reloadProfileEmitter.subscribe(() => {
      this.fetchData();
    });

    $(document).on('click', "#resendEmail", function () {
      Swal.close();
      $(document).find('#verifyEmailButton').click();
    });

    this.contactListHttpService.getContactListByPlatform('2', '1').subscribe(data => {
      if( data[0] !== undefined && data[0]['locales'][0] != undefined ) {
        this.redirectLink = data[0]['locales'][0]['link'] ?? null;
      }
    });

    this.initEmailInputValidator();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'date_of_birth'),
    ]).subscribe();
  }

  ngOnDestroy(): void {
    this.eventEmitterService.profile.unsubscribe();
    this.validateSubject.complete();
  }

  fetchData() {
    this.loadingBar.start();
    this.portalMemberProfileHttpService.getProfile()
      .subscribe(data => {
        this.userData = JSON.parse(localStorage.getItem('user_data'));
        this.userName = data.name;

        this.form.patchValue({
          name: data.name, email: data.email, mobile: data.mobile
        });

        if ((data.name).length > 0) {
          this.form.controls[`name`].disable();
        }
        if (this.isValidDate(data.date_of_birth)) {
          this.form.patchValue({ date_of_birth: data.date_of_birth });
          this.form.controls[`date_of_birth`].disable();
        }
        if (+data['email_status'] === 1) {
          this.form.controls[`email`].disable();
        }
        if (+data['mobile_status'] === 1) {
          this.form.controls[`mobile`].disable();
        }

        this.loadingBar.complete();
      });
  }

  onEmailInput(event) {
    this.validateSubject.next({ value: event.target.value }); 
  }

  onSaveProfile() {
    this.checkValidity = true;
    if (this.form.valid) {
      this.buttonLoading = true;

      var data = {
        ...this.form.value,
        name: this.userName.length > 0 ? this.userName : this.form.value.name,
      };
      if (this.form.value.email && this.form.value.email != null) {
        data['email'] = this.form.value.email.toLowerCase();
      }
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === '0000-00-00') && delete data[key]);
      this.portalMemberProfileHttpService
        .updateProfile(data)
        .pipe(
          catchError((error) => {
            this.buttonLoading = false;
            throw error;
          })
        )
        .subscribe((res) => {
          this.dashboardHttpService.refreshMemberName(res.username);
          this.isSuccess = this.portalMemberProfileHttpService.isSuccess;
          this.checkValidity = false;
          this.buttonLoading = false;
        });
    }
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  onOpenDialog() {
    this.dialog.open(MemberStatusModalComponent, {
      width: 'auto',
      data: {
        member_data: this.userData
      }
    });
  }

  onVerifyNumber() {
    // if( this.countryCode.toUpperCase() == 'ID' ) {
    //   if( this.redirectLink !== undefined && this.redirectLink !== null ) {
    //     window.open(this.redirectLink, "_blank");
    //   } else {
    //     Swal.fire({
    //       html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
    //       '<div class="text-center m-t-20">' + this.translateService.instant('Please contact our Live Chat team for further assistance.') +
    //       '</div>'
    //     });
    //   }
    // } else {
      this.openDialogBy(VerificationModalComponent, JSON.parse(localStorage.getItem('user_data')).otp_status, this.form.value.mobile);
    // }
  }

  requestEmail() {
    this.loadingBar.start();
    this.portalMemberProfileHttpService.requestEmail(this.form.value.email.toLowerCase()).subscribe(() => {
      this.loadingBar.complete();
      Swal.fire({
        html: '<div class="msg-icon-email">' + this.svg.emailIcon + '</div>' +
          '<div class="text-center mt-3">' +
          '<ul><li><h3 class="verification-title">'
          + this.translateService.instant('VERIFICATION EMAIL SENT') +
          '</h3></li><li>'
          + this.translateService.instant('Verification email has been sent to') +
          '</li><li class="email-text">' + this.form.value.email.toLowerCase() + '</li><br><li>'
          + this.translateService.instant("Didn't recieve our email?") +
          '</li><a class="resend-text" id="resendEmail">' + this.translateService.instant('Resend Verification Email') + '</a></ul>' +
          '</div>',
        confirmButtonText: 'OK',
      });
    });
  }

  formatDOB() {
    var dob = this.form.get('date_of_birth').value.replace(/[^0-9]+/g, "");
    var newDOB = '';
    for (var i = 0; i < dob.length; i++) {
      if (i === 4 || i === 6) {
        newDOB += '-';
      }
      newDOB += dob[i];
    }
    this.form.patchValue({
      date_of_birth: newDOB
    })
  }

  dateValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var validDate = false;
      if (control.value == null || control.value == '') {
        validDate = true;
      } else {
        validDate = moment(control.value).isValid() && +moment(control.value).year() <= (+moment().year() - 18);
      }
      return validDate ? null : { invalidDate: control.value }
    }
  }

  private openDialogBy(componentRef: any, can_request_otp?: boolean, new_mobile_number?: string) {
    this.dialog.open(componentRef, {
      width: 'auto',
      data: {
        can_request_otp: can_request_otp,
        new_mobile_number: new_mobile_number
      }
    });
  }

  private verificationMessage() {
    const result = JSON.parse(localStorage.getItem('verify_email_message'));
    if (result !== null) {
      const svgIcon = result.success ? '<div class="msg-icon-greentick">' + this.svg.greenTickIcon + '</div>' : '<div class="msg-icon">' + this.svg.dangerIcon + '</div>';
      Swal.fire({
        html: svgIcon +
          '<div class="text-center mt-3"><ul><li><h3 class="mb-3">'
          + this.translateService.instant('$brand_name Email Verification') +
          '</h3></li><li>'
          + result.message[0] +
          '</li></ul></div>',
        confirmButtonText: this.translateService.instant('Okay'),
      });
      localStorage.removeItem('verify_email_message');
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      }),
    );
  }

  private profileFormInit() {
    this.form = new FormGroup({
      name: new FormControl(null, []),
      email: new FormControl(null, []), // email validation is done entirely on backend
      mobile: new FormControl(null, []),
      date_of_birth: new FormControl(null, [this.dateValidation()]),
    });
  }

  private initEmailInputValidator() {
    this.validateSubject.pipe(
      distinctUntilChanged(),
      debounceTime(300),
    ).subscribe(data => {
      if (data?.value) {
        this.validatingEmail = true;

        this.portalMemberProfileHttpService.validateEmail(
          { email: this.form.get('email').value }
        ).subscribe({
          next: (response) => {
            const formControl = this.form.get('email');

            if (!response.success) {
              formControl.setErrors({ invalid: response.message });
            } else {
              formControl.setErrors(null);
            }
          },
          error: (error) => {
            this.form.get('email').setErrors({ invalid: error.error.message });
          },
          complete: () => {
            this.validatingEmail = false;
          }
        });
      }
    });
  }
}
