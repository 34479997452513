import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-swal-alert',
  templateUrl: './swal-alert.component.html',
  styleUrls: ['./swal-alert.component.scss']
})
export class SwalAlertComponent implements OnInit, OnChanges {
  svg: any = svgIconList;
  @Input()
  success: any;

  @Input()
  message: any[];

  // BY DEFAULT IS 3 SECONDS
  @Input()
  timer = 3000;

  @Input()
  register: boolean; 
  
  @Input()
  login: boolean;

  @Input()
  flashStyle: boolean

  @Output()
  confirmed = new EventEmitter<boolean>();

  @Input()
  color: string = '#000';

  @Input()
  tick: boolean = true;

  @Input()
  promotionProvider: any;

  @Input()
  ewalletVerification: boolean

  customClass: SweetAlertCustomClass = {
    popup: 'customPopup',
    content: 'customContent'
  };

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.alert();
  }

  private alert() {

    // Only HTTP OK from this part!

    if ((!!this.message)) {
      let messages = '<ul style="margin:0px; padding: 0px">';
      if (this.register) {
        messages += '<ul><li>' + this.translateService.instant('Congratulations! You have successfully registered as a member') + '</li></ul>';
      } else {
        this.message.map((row: any) => messages += `<ul>${row}</li>`);
        if (this.promotionProvider !== null && this.promotionProvider !== undefined) {
          if (this.promotionProvider.id === 4 || this.promotionProvider.id === 6 && localStorage.getItem('country_code') === 'ID') {
            messages += `<li class="color-20"> ${this.translateService.instant('Exchange Rate')} <br> IDR 1:0.2 ${this.promotionProvider.name.toUpperCase()} ${this.translateService.instant('Credits')}</li>`
          }
        }
        messages += '</ul>';
      }

      if (this.register || this.login || this.flashStyle) {
        Swal.fire({
          html: `<div class="text-center" style="color:${this.color};">` + messages + '</div>',
          toast: true,
          showConfirmButton: false,
          showCloseButton: true,
          customClass: this.customClass,
          position: 'top',
          timer: this.timer,
          hideClass: {
            popup: `animate__animated animate__bounceOutUp`
          },
          ...(this.tick && { icon: 'success' })
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.confirmed.emit(true);
          }
        });
      } else if (this.ewalletVerification) {
        Swal.fire({
          html: '<div class="msg-icon-underreview">' + this.svg.underReviewIcon + '</div>' +
                '<div class="text-center m-t-20" style="font-size:18px; font-weight: 700;">'+ this.translateService.instant('Under Review') + '</div>' +
                '<div class="text-center m-t-20">' +
                  messages +
                '</div>' 
        }).then(result => {
          if (result) {
            this.confirmed.emit(true);
          }
        });
      } else {
        Swal.fire({
          html: '<div class="msg-icon-greentick">' + this.svg.greenTickIcon + '</div>' +
                '<div class="text-center m-t-20">' +
                  messages +
                '</div>'
        }).then(result => {
          if (result) {
            this.confirmed.emit(true);
          }
        });
      }
    }
  }

}
