// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { QRCodeElementType } from 'angularx-qrcode';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
// Model
import { Pagination } from '@core/models/pagination.model';
import { ReferralHistory } from '@core/models/referral-history';
// Service
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ReferralHttpService } from '@core/services/referral-http.service';
import { DateFilterService } from '@core/services/date-filter.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
// RxJS
import { Observable, of, Subscription } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import svgIconList from 'assets/icons.json';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { ClaimRewardModalComponent } from '../../dialog/claim-reward-modal/claim-reward-modal.component';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss'],
  host: {
    '(document:mousedown)': 'onClickOutside($event)',
  }
})
export class ReferralsComponent implements OnInit {
  svg: any = svgIconList;

  @ViewChild('tooltipCode') tooltipCode: MatTooltip;
  @ViewChild('tooltipLink') tooltipLink: MatTooltip;
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  details: any = {};
  history: Observable<ReferralHistory[]>;
  currency: any;
  tabType = 'downline';
  userData = JSON.parse(localStorage.getItem('user_data'));

  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  params = `pagination=true`;
  elementType = "canvas" as QRCodeElementType;
  showMultiTierCommission: boolean = false;
  showDateTimePicker = false;
  form: FormGroup;
  activeFilter = {
    date: 'All Time'
  };
  filterButtons = {
    date: ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'Last 90 Days', 'All Time']
  };
  isLoading = false;
  downlineList$ = [];
  referralBonusHistory$ = [];
  commissionHistory$ = [];
  totalDownlinePlayer = 0;
  levelsCountRateLength = 0;
  private datePickerSubscription = new Subscription();

  constructor(
    private referralHttpService: ReferralHttpService,
    private loadingBar: LoadingBarService,
    private datePipe: DatePipe,
    private dateFilterService: DateFilterService,
    private _eref: ElementRef,
    private dialog: MatDialog,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.currency = this.userData.currency;
    this.formInit();
    if (this.userData.KYC === 'pro') {
      this.referralHttpService.getDetails().subscribe(
        (res) => {
          this.details = res;
          this.showMultiTierCommission = res['isRefCommTier'];
          this.levelsCountRateLength = Object.keys(res.levels_count_rate).length;
        },
        (err) => {
          this.details = {};
        },
        () => { });
      this.onViewPageBy();
    }
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();

    if (this.tabType === 'downline') {
      return this.getDownlineList(page, pageSize, params);
    } else if (this.tabType === 'bonus') {
      return this.getReferralBonusHistory(page, pageSize, params);
    } else if (this.tabType === 'commission') {
      return this.getCommissionHistory(page, pageSize, params);
    }
  }

  onCopyText(inputElement: any, type: string) {
    inputElement.select();
    document.execCommand('copy');

    if (type === 'code') {
      this.tooltipCode.show();
      setTimeout(() => this.tooltipCode.hide(), 2000);
    } else {
      this.tooltipLink.show();
      setTimeout(() => this.tooltipLink.hide(), 2000);
    }
  }

  getQRSignupLink(refcode:any){
    return window.location.origin + '/signup?ref=' + refcode;
  }

  onDownloadQR(parent: any) {
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.")
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "angularx-qrcode"
      link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }

  changeTab(type: string) {
    if (this.tabType !== type) {
      this.tabType = type;
      this.onReset('date');
    }
  }

  onHideCalendar() {
    if (this.showDateTimePicker = true) {
      this.showDateTimePicker = false;
    }
  }

  onDateFilter(type: any, event?: any) {
    this.activeFilter.date = type;
    switch (type) {
      case 'Today':
        this.form.patchValue({
          start_datetime: this.dateFilterService.getToday().from,
          end_datetime: this.dateFilterService.getToday().to
        });
        break;
      case 'Yesterday':
        this.form.patchValue({
          start_datetime: this.dateFilterService.getYesterday().from,
          end_datetime: this.dateFilterService.getYesterday().to
        });
        break;
      case 'Last 7 Days':
        this.form.patchValue({
          start_datetime: this.dateFilterService.getLast7Days().from,
          end_datetime: this.dateFilterService.getLast7Days().to
        });
        break;
      case 'Last 30 Days':
        this.form.patchValue({
          start_datetime: this.dateFilterService.getLast30Days().from,
          end_datetime: this.dateFilterService.getLast30Days().to
        });
        break;
      case 'Last 90 Days':
        this.form.patchValue({
          start_datetime: this.dateFilterService.getLast90Days().from,
          end_datetime: this.dateFilterService.getLast90Days().to
        });
        break;
      case 'All Time':
        this.form.patchValue({
          start_datetime: null,
          end_datetime: null
        });
        break;
      case 'custom range':
        if (event) {
          this.form.patchValue({
            start_datetime: moment(event.startDate._d).format('YYYY-MM-DD'),
            end_datetime: moment(event.endDate._d).format('YYYY-MM-DD')
          });
        }
    }
    this.onSubmit();
  }

  onShowCalendar() {
    this.showDateTimePicker = true;
  }

  onClose() {
    this.showDateTimePicker = false;
  }

  onReset(type: string) {
    switch (type) {
      case 'date':
        this.activeFilter.date = 'All Time';
        this.form.patchValue({
          start_datetime: null,
          end_datetime: null
        });
        break;
    }
    this.onSubmit();
  }

  private formInit() {
    this.form = new FormGroup({
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null)
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all time') {
        fields[key] = key === 'start_datetime' ? moment(formData[key] + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss') : key === 'end_datetime' ? moment(formData[key] + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss') : formData[key];
      }
    });
    return fields;
  }

  onClickOutside(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      of(null).pipe(
        delay(105), tap(() => this.onHideCalendar()
        )).subscribe();
    }
  }

  onSubmit() {
    this.loadingBar.start();
    const data = this.filterFormFields(this.form.value);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.showDateTimePicker = false;

    if (this.tabType === 'downline') {
      return this.getDownlineList(1, this.pageSize, parameters);
    } else if (this.tabType === 'bonus') {
      return this.getReferralBonusHistory(1, this.pageSize, parameters);
    } else if (this.tabType === 'commission') {
      return this.getCommissionHistory(1, this.pageSize, parameters);
    }
  }

  private getDownlineList(page: number, pageSize: number, params: string) {
    this.downlineList$ = [];
    this.isLoading = true;
    this.referralHttpService.getDownlineList(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.referralHttpService.pagination;
        this.totalDownlinePlayer = this.referralHttpService.pagination?.total;
        res.map((row: any) => {
          this.downlineList$.push({
            ...row,
            created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
          });
        });
        this.isLoading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  private getReferralBonusHistory(page: number, pageSize: number, params: string) {
    this.referralBonusHistory$ = [];
    this.isLoading = true;
    this.referralHttpService.getHistory(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.referralHttpService.pagination;
        res.map((row: any) => {
          this.referralBonusHistory$.push({
            ...row,
            created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
          });
        });
        this.isLoading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  private getCommissionHistory(page: number, pageSize: number, params: string) {
    this.commissionHistory$ = [];
    this.isLoading = true;
    this.referralHttpService.getCommissionHistory(`?page=${page}&perPage=${pageSize}&type=6&commission_history=true${params}`).pipe(
      tap(res => {
        this.pagination = this.referralHttpService.pagination;
        res.map((row: any) => {
          this.commissionHistory$.push({
            ...row,
            created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
          });
        });
        this.isLoading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onClaim(row: any) {
    const dialogRef = this.dialog.open(ClaimRewardModalComponent, {
      width: 'auto',
      data: {
        reward: row
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result === true) {
        this.onViewPageBy(this.page); 
        //update reward bubble
        this.eventEmitterService.onUpdateRewardBubble();
      }
    }); 
  }
}
